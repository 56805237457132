<template>
    <div>
        <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
            <v-row justify="center" class="mb-8">
                <v-col class="pl-0 pr-0 text-left" cols="12">
                    <!-- Título dinámico con el nombre del programa -->
                    <h1 class="candidate-details__title">Ganadores {{ programaNombre }}</h1>
                </v-col>
            </v-row>

            <!-- Mostrar los ganadores si existen -->
            <v-row v-if="winners.length > 0">
                <v-col v-for="(persona, index) in winners" :key="index" cols="12" md="3">
                    <!-- Asignación dinámica de clase al card -->
                    <v-card :class="'card-all-winners-' + programaClase">
                        <v-card-text>
                            <div class="container-content-card">
                                <v-img :src="persona.photo" width="125" height="125" class="mb-2"></v-img>
                                <div class="content-card text-center">
                                    <p style="font-size: 24px; text-transform: capitalize;"> {{ persona.nombre }} {{ persona.apellido }}</p>
                                    <p style="font-size: 16px;"> {{ persona.cargo }}</p>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <!-- Si no hay ganadores, mostramos un mensaje -->
            <v-row v-else>
                <v-col cols="12">
                    <p>No se encontraron ganadores para este periodo.</p>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // Variables para almacenar el nombre del programa y los ganadores
            programaNombre: '',  // Nombre del programa
            programaClase: '',   // Clase dinámica para el programa
            winners: []          // Array de ganadores
        };
    },

    created() {
        // Cargar los datos desde localStorage
        const periodo = localStorage.getItem('selectedPeriod');
        const ganadores = localStorage.getItem('selectedWinners');
        const nombrePrograma = localStorage.getItem('selectedProgramName');  // Nombre del programa


        if (periodo && ganadores && nombrePrograma) {
            // Asignar el nombre del programa, clase dinámica y ganadores a las variables
            this.programaNombre = nombrePrograma;
            this.programaClase = nombrePrograma.toLowerCase().replace(/\s+/g, '-');  // Convierte el nombre a una clase válida
            this.winners = JSON.parse(ganadores);  // Parseamos los ganadores de localStorage
        } else {
            console.error("No se encontraron datos en localStorage.");
        }
    },

    beforeRouteLeave(to, from, next) {
        // Limpiamos los datos de localStorage antes de cambiar la ruta
        console.log('Limpiando datos de localStorage');
        localStorage.removeItem('selectedPeriod');
        localStorage.removeItem('selectedWinners');
        localStorage.removeItem('selectedProgramName');  // Limpiamos el nombre del programa
        next(); // Continuamos con la navegación
    }
};
</script>

<style scoped>

/* Estilos super stars */
.card-all-winners-super-stars {
    position: relative;
    height: 550px !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #466be3 !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.container-content-card {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.container-content-card .v-image {
    border: 4px solid #fff;
    border-radius: 50% !important;
    margin-bottom: 35px !important;
}

.container-content-card .content-card {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: start;
}

.card-all-winners-super-stars::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.card-all-winners-super-stars::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* Estilos rappi ranking   */

.card-all-winners-rappi-ranking{
    position: relative;
    height: 550px !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #fc3c24 !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.container-content-card {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.container-content-card .v-image {
    border: 4px solid #fff;
    border-radius: 50% !important;
    margin-bottom: 35px !important;
}

.container-content-card .content-card {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: start;
}

.card-all-winners-rappi-ranking::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.card-all-winners-rappi-ranking::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/star-icon-white.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* Estilos Ace of Spades */

.card-all-winners-ace-of-spades{
    position: relative;
    height: 550px !important;
    border-radius: 25px !important;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: #ac141c !important;
    border: solid #fff;
    border-radius: 25px !important;
}

.container-content-card {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-flow: column;
}

.container-content-card .v-image {
    border: 4px solid #fff;
    border-radius: 50% !important;
    margin-bottom: 35px !important;
}

.container-content-card .content-card {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    text-align: start;
}

.card-all-winners-ace-of-spades::before {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/spade-gold.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.card-all-winners-ace-of-spades::after {
    content: '';
    position: absolute;
    bottom: 15px;
    right: 15px;
    width: 50px;
    height: 50px;
    background-image: url('~@/assets/icon/spade-gold.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

</style>